import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  TextField,
  Stack,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { getChat, DEFAULT_PROMPT_CONFIG } from "../util/CallOpenAI";
import { textToSpeech, pauseTextToSpeech } from "../util/TextToSpeech";
import ChatInputBox from "./ChatInputBox";
import useSpeechToText from "react-hook-speech-to-text";
import { ChatMessage } from "../type/messageTypes";
import { getLast10AssistantMessages } from "../util/util";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChatBubbleV2 from "./ChatBubbleV2";
import SpeakingQuestion, { SpeakingQuestionType } from "./SpeakingQuestion";
import data from "../data/speaking_questions.json";
import { getQuestionByTopic, transformData } from "../util/util";

const DATA = transformData(data);

const initialWelcomMessage: ChatMessage = {
  role: "assistant",
  content: "How are you today!",
};

const VoiceChat = () => {
  const [previousInterimResults, setPreviousInterimResults] =
    useState<string>("");
  const [userInput, setUserInput] = useState<string>("");
  const location = useLocation();
  const { state } = location;
  const [currentQuestion, setQurrentQuestion] = useState<SpeakingQuestionType>(
    DATA[0]
  );
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([
    initialWelcomMessage,
  ]);
  const [loading, setLoading] = useState(false);
  const [isChatBotSpeaking, setIsChatBotSpeaking] = useState(false);
  const chatHistoryRef = useRef<null | HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const questionSelected = getQuestionByTopic(DATA, state);
    if (questionSelected) setQurrentQuestion(questionSelected);
  }, [state]);

  useEffect(() => {
    chatHistoryRef.current?.scrollIntoView(false);
  }, [chatHistory]);

  const handleChatSubmission = useCallback(
    async (message?: string) => {
      console.log("handleChatSubmission");
      currentText.current = "";
      setPreviousInterimResults("");
      pauseTextToSpeech();
      if (loading) {
        return;
      }
      const input = typeof message == "string" ? message : userInput;

      if (input.trim() === "") {
        return;
      }
      setUserInput("");
      // Add user message to chat history
      const userMessage: ChatMessage = { role: "user", content: input };
      setChatHistory((prevChatHistory) => [...prevChatHistory, userMessage]);
      // Add placeholder message to indicate that we're waiting for a response
      const placeholderMessage: ChatMessage = {
        role: "assistant",
        content: "...",
      };
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        placeholderMessage,
      ]);

      try {
        setLoading(true);
        // Get response from ChatGPT
        console.log("currentQuestion", currentQuestion);
        const result = await getChat(
          input,
          DEFAULT_PROMPT_CONFIG,
          {
            accessToken: undefined,
          },
          currentQuestion,
          getLast10AssistantMessages(chatHistory)
        );
        setLoading(false);
        // Replace the placeholder message with the actual response
        const assistantMessage: ChatMessage = {
          role: "assistant",
          content: result.message,
        };
        setChatHistory((prevChatHistory) =>
          prevChatHistory.map((message) =>
            message === placeholderMessage ? assistantMessage : message
          )
        );

        // Text-to-speech for the assistant's response
        textToSpeech(result.message, setIsChatBotSpeaking);
      } catch (error) {
        // Remove the placeholder message and show an error message instead
        setChatHistory((prevChatHistory) =>
          prevChatHistory.filter((message) => message !== placeholderMessage)
        );
        console.error("Error in handleChatSubmission:", error);
      }
    },
    [userInput, chatHistory]
  );

  const handleClearChatHistory = () => {
    if (loading) {
      // do nothing if isLoading is true
      return;
    }
    setChatHistory([]);
  };

  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    speechRecognitionProperties: {
      lang: "zh-CN",
      interimResults: true, // Allows for displaying real-time speech results
    },
    continuous: true,
    useLegacyResults: false,
  });

  //When user stop taling, interimResult is undefined, but when user start again, interim result will start with the
  //restarted text, therefore removing previous interim results. which is not the behavior we want. Adding this current
  //text and previousInterim result to help keep track where the result were before user pauses.
  const currentText = useRef("");

  useEffect(() => {
    if (interimResult) {
      setUserInput(previousInterimResults + interimResult);
      currentText.current = previousInterimResults + interimResult;
    } else {
      setPreviousInterimResults(currentText.current);
    }
  }, [interimResult, previousInterimResults]);

  const handleStopSpeechToText = (): void => {
    stopSpeechToText();
    // handleChatSubmission();
  };

  const handleUserInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserInput(event.target.value);
    },
    []
  );
  const handleChatbotSpeakingStop = (): void => {
    setIsChatBotSpeaking(false);
    pauseTextToSpeech();
  };

  function handleSpeechToText() {
    if (loading) {
      // do nothing if isLoading is true
      return;
    }

    if (isRecording) {
      handleStopSpeechToText();
    } else {
      startSpeechToText();
    }
  }

  const handleQuestionChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    const question = getQuestionByTopic(DATA, event.target.value);
    if (question) setQurrentQuestion(question);
  };

  return (
    <Stack
      sx={{
        height: "calc(100vh - 60px)",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <FormControl
        sx={{
          marginTop: "80px",
          width: "300px",
        }}
        fullWidth
      >
        <InputLabel id="demo-simple-select-label">当前题目</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentQuestion ? currentQuestion.topic : "Art"}
          label="当前题目"
          onChange={handleQuestionChange}
        >
          {DATA.map((question, idx) => {
            return (
              <MenuItem key={idx} value={`${question.topic}`}>
                {question.topic}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={6}
          sm={8}
          md={12}
          sx={{ mb: "120px" }}
          alignItems="center"
          justifyContent="center"
        >
          {chatHistory.map((chat: ChatMessage, index: number) => (
            <Grid item key={index}>
              {/* <ChatBubble index={index} chat={chat} isMobile={isMobile} /> */}
              <ChatBubbleV2 sender={chat.role} message={chat.content} />
              {/* {getTextFieldbyRole(chat, index, isMobile)} */}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <ChatInputBox
        setUserInput={setUserInput}
        userInput={userInput}
        userSpeaking={isRecording}
        chatBotSpeaking={isChatBotSpeaking}
        handleUserInput={handleUserInput}
        handleChatSubmission={handleChatSubmission}
        handleSpeechRecognition={handleSpeechToText}
        handleChatbotSpeakingStop={handleChatbotSpeakingStop}
        handleClearChatHistory={handleClearChatHistory}
      />
      <div ref={chatHistoryRef} />
    </Stack>
  );
};

export default VoiceChat;
