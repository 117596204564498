import React, { useState } from "react";
import { styled } from "@mui/system";
import {
  Typography,
  Avatar,
  Paper,
  IconButton,
  CircularProgress,
  Divider,
  Tooltip,
  Stack,
} from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import TranslateIcon from "@mui/icons-material/Translate";
import AssistantIcon from "@mui/icons-material/Assistant";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import { translate, suggest, checkGrammar } from "../util/CallOpenAI";
import { textToSpeech, pauseTextToSpeech } from "../util/TextToSpeech";

const CHATGPT_ICON_URL =
  "https://img.uxwing.com/wp-content/themes/uxwing/download/brands-social-media/chatgpt-icon.svg";

const RootContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  minWidth: "350px", // Set a default minimum width
  [theme.breakpoints.up("sm")]: {
    minWidth: "500px",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: "750px",
  },
}));

const AvatarContainer = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const MessageContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  minWidth: "300px", // Set a default minimum width
  [theme.breakpoints.up("sm")]: {
    width: "500px",
  },
  [theme.breakpoints.up("md")]: {
    width: "750px",
  },
  padding: theme.spacing(1.5),
  backgroundColor: "#F5F5F5",
  borderRadius: theme.spacing(1),
}));

const DividerLine = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(1)}px 3`,
  backgroundColor: theme.palette.primary.main,
}));

const ControlContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
  margin: theme.spacing(1),
}));

interface ChatBubbleV2Props {
  sender: string;
  message: string;
  isLoading?: boolean;
}

const ChatBubbleV2: React.FC<ChatBubbleV2Props> = ({ sender, message }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpeakingText, setIsSpeakingText] = useState(false);
  const [translation, setTranslation] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [grammarText, setGrammarText] = useState("");

  const handleGetTranslation = async () => {
    if (isLoading || translation !== "") {
      return;
    }
    setIsLoading(true);
    const responseStream = await translate(message);
    setIsLoading(false);
    const reader = responseStream.getReader();
    let output = "";
    let done = false;
    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;
      if (value) output += value;
      setTranslation(output);
      setDisplayText(output);
    }
  };

  const handleCheckGrammar = async () => {
    if (isLoading || grammarText !== "") {
      return;
    }
    setIsLoading(true);
    const responseStream = await checkGrammar(message);
    setIsLoading(false);
    const reader = responseStream.getReader();
    let output = "";
    let done = false;
    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;
      if (value) output += value;
      setGrammarText(output);
      setDisplayText(output);
    }
  };

  const handleSpeak = async () => {
    if (isSpeakingText) {
      pauseTextToSpeech();
    } else {
      textToSpeech(message, setIsSpeakingText);
    }
  };

  const handleGetSuggestion = async () => {
    if (isLoading || suggestion !== "") {
      return;
    }
    setIsLoading(true);
    const responseStream = await suggest(message);
    setIsLoading(false);
    const reader = responseStream.getReader();
    let output = "";
    let done = false;
    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;
      if (value) output += value;
      setSuggestion(output);
      setDisplayText(output);
    }
  };

  return (
    <RootContainer>
      <AvatarContainer
        alt={sender}
        src={sender === "assistant" ? CHATGPT_ICON_URL : ""}
      />
      <MessageContainer elevation={0}>
        <Typography sx={{ textAlign: "left" }} variant="body1">
          {message}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width={"100%"}
        >
          <IconButton
            onClick={handleGetTranslation}
            size="small"
            color="primary"
            aria-label="Translate"
          >
            {/* <TranslateIcon /> 翻译 */}
            翻译
          </IconButton>
          {sender === "assistant" ? (
            <Tooltip title="示例回答" placement="bottom-end">
              <IconButton
                onClick={handleGetSuggestion}
                size="small"
                color="primary"
                aria-label="Speak"
                sx={{ p: 1 }}
              >
                <AssistantIcon /> 示例
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="检查语法" placement="bottom-end">
              <IconButton
                onClick={handleCheckGrammar}
                size="small"
                color="primary"
                aria-label="Speak"
              >
                <SpellcheckIcon />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            onClick={handleSpeak}
            size="small"
            color="primary"
            aria-label="Suggest"
          >
            <CampaignIcon
              style={{ color: isSpeakingText ? "#8BC34A" : "inherit" }}
            />
          </IconButton>
        </Stack>
        {isLoading && <LoadingIndicator size={20} />}
        {displayText && <DividerLine />}
        {displayText && (
          <Typography sx={{ textAlign: "left" }} variant="body1">
            {displayText}
          </Typography>
        )}
      </MessageContainer>
    </RootContainer>
  );
};

export default ChatBubbleV2;
