import { ChatMessage } from "../type/messageTypes";
import { SpeakingQuestionType } from "../components/SpeakingQuestion";
export const transformData = (data: any[]): SpeakingQuestionType[] => {
  return data.map((item) => ({
    topic: item.topic,
    asks: item.asks.map((askItem: any) => ({
      ask: askItem.ask,
      answer: askItem.answer,
      userAnswer: "",
      feedback: "",
    })),
  }));
};

export function isGiberish(str: string) {
  const commonWords = new Set([
    "the",
    "of",
    "and",
    "to",
    "in",
    "that",
    "is",
    "for",
    "it",
    "with",
    "on",
    "was",
    "be",
    "by",
    "as",
    "are",
    "at",
    "from",
    "an",
    "also",
    "have",
    "has",
    "had",
    "but",
    "which",
    "not",
    "this",
    "one",
    "or",
    "their",
    "they",
    "all",
    "we",
    "you",
    "can",
    "more",
    "if",
    "out",
    "up",
    "what",
    "about",
    "other",
    "will",
    "into",
    "than",
    "some",
    "so",
    "its",
    "then",
    "these",
    "two",
    "when",
    "time",
    "no",
    "just",
    "him",
    "know",
    "take",
    "person",
    "year",
    "see",
    "right",
    "even",
    "off",
    "good",
    "like",
    "first",
    "made",
    "make",
    "them",
    "over",
    "way",
    "only",
    "after",
    "day",
    "most",
    "us",
    "such",
    "then",
    "m",
    "it's",
    "don't",
    "i'm",
    "he's",
    "she's",
    "we're",
    "they're",
    "i've",
    "you've",
    "we've",
    "they've",
    "i'll",
    "you'll",
    "he'll",
    "she'll",
    "we'll",
    "they'll",
    "it'll",
    "can't",
    "won't",
    "shouldn't",
    "wouldn't",
    "didn't",
    "hadn't",
    "hasn't",
    "aren't",
    "isn't",
    "couldn't",
    "mightn't",
    "mustn't",
    "needn't",
    "oughtn't",
    "shan't",
    "that's",
    "there's",
    "here's",
    "what's",
    "who's",
    "where's",
    "when's",
    "why's",
    "how's",
    "a",
    "an",
    "am",
    "at",
    "as",
    "are",
    "by",
    "be",
    "but",
    "do",
    "does",
    "doing",
    "done",
    "go",
    "get",
    "got",
    "had",
    "has",
    "have",
    "here",
    "him",
    "her",
    "his",
    "if",
    "into",
    "it",
    "its",
    "just",
    "me",
  ]);
  const words = str.match(/[a-z]+/gi);

  if (!words) {
    return true;
  }

  const numCommonWords = words.filter((word) =>
    commonWords.has(word.toLowerCase())
  ).length;

  const ratioCommonWords = numCommonWords / words.length;

  return ratioCommonWords < 0.2; // less than half of words are common
}

export function getLast10AssistantMessages(
  chatHistory: ChatMessage[]
): ChatMessage[] {
  // Filter for messages with role = 'assistant'
  // const assistantMessages = chatHistory.filter(
  //   (message) => message.role === "assistant"
  // );
  // Get the last 10 messages or fewer if there are less than 10
  const last10Messages = chatHistory.slice(-10);
  return last10Messages;
}

export function insertSystemMessage(
  content: string,
  history?: Array<{ role: string; content: string }>
) {
  if (!history) {
    return [{ role: "system", content }];
  }
  const newHistory = [{ role: "system", content }, ...history];
  return newHistory;
}

export function getQuestionByTopic(
  questions: SpeakingQuestionType[],
  questionName: string
): SpeakingQuestionType | null {
  for (const question of questions) {
    if (question.topic === questionName) {
      return question;
    }
  }
  return null; // Return null if the question name is not found
}
