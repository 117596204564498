import React, { FC, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import MenuDrawer from "./MenuDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import Navigation from "./Navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  ROUTE_ESTIMATE,
  ROUTE_CHAT,
  ROUTE_SAMPLE,
  ROUTE_OUTLINE,
} from "../routes/routes";

const BACKGROUND_COLOR = "#fff";
const TEXT_COLOR = "#000";

const SmallScreenMenu: FC<{ pathname: string }> = ({ pathname }) => {
  const getTitle = () => {
    switch (pathname) {
      case ROUTE_CHAT:
        return "Voice Chat";
      case ROUTE_ESTIMATE:
        return "Estimate Band";
      case ROUTE_OUTLINE:
        return "Get Outline";
      case ROUTE_SAMPLE:
        return "Generate Sample";
      default:
        return null;
    }
  };

  const title = getTitle();

  return <div>{title}</div>;
};

const TopBar: FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const appBarStyle = {
    marginBottom: "50px",
  };

  const toolbarStyle = {
    backgroundColor: BACKGROUND_COLOR,
    color: TEXT_COLOR,
    display: "flex",
    justifyContent: "space-between",
  };

  return (
    <>
      <AppBar elevation={2} position="fixed" style={appBarStyle}>
        <Toolbar style={toolbarStyle}>
          {isMobile ? (
            <IconButton onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          ) : (
            <div></div>
          )}

          {!isMobile && <Navigation />}
          {isMobile && <SmallScreenMenu pathname={location.pathname} />}
          {isMobile && (
            <MenuDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
          )}
          <div></div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;
