import process from "process";

import mixpanel from "mixpanel-browser";
const DEV_MP_TOKEN = "c2880424f0ffee6dffd11ede30ff9075";
const PROD_MP_TOKEN = "4ed6ea6662fac5c5028b7aac9cc4cde4";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  mixpanel.init(DEV_MP_TOKEN, { debug: true });
} else {
  // production code
  mixpanel.init(PROD_MP_TOKEN, { debug: true });
}

let env_check = process.env.NODE_ENV === "production";

//Event Interface
export interface GPTApiCallInterface {
  action: string;
  output: string;
}

export const Events = {
  GPTApiCall: "GPTApiCall",
  Error: "Error",
};

export interface ErrorInterface {
  message: string;
  stack?: string;
  errorInfo?: React.ErrorInfo;
}
//Events
export const Actions = {
  Estimate: "estimate",
  Outline: "outline",
  Feedback: "feedback",
  Sample: "sample",
  Chat: "chat",
  OutlineToEssay: "outline-to-essay",
};

//Actions
let actions = {
  track: (name: string, props: any) => {
    if (env_check) mixpanel.track(name, props);
  },
};

function logEvent(eventName: string, data: object) {
  mixpanel.track(eventName, data);
}

export function withLogging(apiFunc: Function, eventName: string) {
  return async function (...args: any[]) {
    const startTime = Date.now();

    try {
      const result = await apiFunc(...args);
      const endTime = Date.now();
      logEvent(eventName, {
        arguments: args,
        responseTimeSec: (endTime - startTime) / 1000,
      });
      return result;
    } catch (error) {
      const endTime = Date.now();
      logEvent(Events.Error, {
        eventName: eventName,
        arguments: args,
        responseTimeSec: (endTime - startTime) / 1000,
        error,
      });
      throw error;
    }
  };
}

export let mp = actions;
