import React, { useState } from "react";
import { TextField, Button, Box, Stack, CircularProgress } from "@mui/material";
import {
  getWritingBandEstimate,
  DEFAULT_PROMPT_CONFIG,
} from "../util/CallOpenAI";
import { ScoreEssayInput } from "../type/InputTypes";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { isGiberish } from "../util/util";

export default function EstimateBand() {
  const [loading, setLoading] = React.useState(false);
  const [promptValue, setPromptValue] = useState("");
  const [essayValue, setEssayValue] = useState("");
  const [coherenceValue, setCoherenceValue] = useState<string>("");
  const [grammaticalValue, setGrammaticalValue] = useState<string>("");
  const [taskValue, setTaskValue] = useState<string>("");
  const [lexicalValue, setLexicalValue] = useState<string>("");
  const [bandValue, setBandValue] = useState<string>("");

  function parseScore(str: string) {
    const taskEstimate = str.match(/Task Response: (\d+(\.\d+)?)/)?.[1];
    const coherenceEstimate = str.match(
      /Coherence and Cohesion: (\d+(\.\d+)?)/
    )?.[1];
    const lexicalEstimate = str.match(/Lexical Resource: (\d+(\.\d+)?)/)?.[1];
    const grammaticalEstimate = str.match(
      /Grammatical Range and Accuracy: (\d+(\.\d+)?)/
    )?.[1];

    const overallband = roundToNearestHalf(
      (parseFloat(coherenceEstimate || "0") +
        parseFloat(taskEstimate || "0") +
        parseFloat(lexicalEstimate || "0") +
        parseFloat(grammaticalEstimate || "0")) /
        4
    );
    return {
      taskEstimate,
      coherenceEstimate,
      lexicalEstimate,
      grammaticalEstimate,
      overallband,
    };
  }

  function roundToNearestHalf(num: number) {
    return Math.round(num * 2) / 2;
  }

  const handleCreateButtonClick = async (event: any) => {
    event.preventDefault();
    setCoherenceValue("");
    setGrammaticalValue("");
    setBandValue("");
    setLexicalValue("");
    setTaskValue("");

    if (!essayValue) {
      return;
    }

    if (essayValue.length < 100) {
      alert("Essay is too short. Please write at least 100 words.");
      return;
    }

    // Check if input is gibberish using regular expression
    if (isGiberish(essayValue)) {
      alert("Unrecognized input. Please check your essay and try again.");
      return;
    }

    const essayInput: ScoreEssayInput = {
      prompt: promptValue.trim(),
      essay: essayValue.trim(),
    };

    setLoading(true);

    try {
      const result = await getWritingBandEstimate(
        essayInput.prompt,
        essayInput.essay,
        DEFAULT_PROMPT_CONFIG,
        {
          accessToken: undefined,
        }
      );
      console.log(result);
      const extractedResult = parseScore(result.message);
      console.log(extractedResult);

      if (extractedResult.coherenceEstimate !== undefined) {
        setCoherenceValue(extractedResult.coherenceEstimate.toString());
      }

      if (extractedResult.grammaticalEstimate !== undefined) {
        setGrammaticalValue(extractedResult.grammaticalEstimate.toString());
      }

      if (extractedResult.taskEstimate !== undefined) {
        setTaskValue(extractedResult.taskEstimate.toString());
      }

      if (extractedResult.lexicalEstimate !== undefined) {
        setLexicalValue(extractedResult.lexicalEstimate.toString());
      }
      if (extractedResult.overallband !== undefined) {
        setBandValue(extractedResult.overallband.toString());
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setLoading(false);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-evenly"
      alignItems="center"
      sx={{
        mt: 10,
      }}
    >
      <Stack spacing={3}>
        <TextField
          value={promptValue}
          label="Prompt"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: "600px" }}
          placeholder="Type or paste an essay question"
          rows={4}
          multiline
          onChange={(event) => {
            setPromptValue(event.target.value);
          }}
        ></TextField>
        <TextField
          InputProps={{
            inputComponent: TextareaAutosize,
            inputProps: {
              minRows: "8",
              style: {
                resize: "none",
              },
            },
          }}
          value={essayValue}
          label="Essay"
          sx={{ height: "auto" }}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Type or paste your essay"
          disabled={loading}
          onChange={(event) => {
            setEssayValue(event.target.value);
          }}
        ></TextField>
      </Stack>
      <Stack spacing={3}>
        <Box sx={{ m: 1, position: "relative" }}>
          <Button
            variant="contained"
            onClick={handleCreateButtonClick}
            sx={{ mb: "8px" }}
            disabled={loading}
          >
            Score Essay
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
        <TextField
          value={bandValue}
          label="Overall Band"
          multiline
          disabled
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          value={coherenceValue}
          label="COHERENCE AND COHESION"
          multiline
          disabled
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          value={lexicalValue}
          label="LEXICAL RESOURCE:"
          multiline
          disabled
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          value={grammaticalValue}
          label="GRAMMATICAL RANGE:"
          multiline
          disabled
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <TextField
          value={taskValue}
          label="TASK ACHIEVEMENT:"
          multiline
          disabled
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
      </Stack>
    </Stack>
  );
}
