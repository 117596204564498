export const ROUTE_HOME = "/";
export const ROUTE_ESTIMATE = "/estimate";
export const ROUTE_OUTLINE = "/outline";
export const ROUTE_SAMPLE = "/sample";
export const ROUTE_UPGRADE = "/upgrade";
export const ROUTE_SIGNUP = "/signup";
export const ROUTE_LOGIN = "/login";
export const ROUTE_CHAT = "/chat";
export const ROUTE_SPEAKING_PRACTICE = "/speakingpractice";
export const ROUTE_FORGET_PASSWORD = "/forgetpassword";
