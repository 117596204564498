import axios from "axios";
// import * as speech from "@google-cloud/speech";

const GOOGLE_API_KEY = "AIzaSyBKJYTlCxm54cWhv6WetjGfxq0fQS9fqcg";

let audio: HTMLAudioElement | undefined;

export async function textToSpeech(
  text: string,
  setIsChatBotSpeaking: any
): Promise<void> {
  try {
    const response = await axios.post(
      `https://texttospeech.googleapis.com/v1/text:synthesize?key=${GOOGLE_API_KEY}`,
      {
        input: {
          text: text,
        },
        voice: {
          languageCode: "en-US",
          ssmlGender: "FEMALE",
          name: "en-US-Neural2-F",
        },
        audioConfig: {
          audioEncoding: "MP3",
        },
      }
    );

    const audioContent = response.data.audioContent;
    const audioBlob = new Blob(
      [new Uint8Array(Array.from(atob(audioContent), (c) => c.charCodeAt(0)))],
      { type: "audio/mp3" }
    );
    const audioUrl = URL.createObjectURL(audioBlob);

    audio = new Audio(audioUrl);
    audio.onplay = () => {
      setIsChatBotSpeaking(true);
    };
    audio.onended = () => {
      setIsChatBotSpeaking(false);
    };
    audio.play();
  } catch (error) {
    console.error("Error fetching speech audio:", error);
  }
}

export function pauseTextToSpeech(): void {
  if (audio) {
    // audio.currentTime = 0;
    audio.pause();
    audio.dispatchEvent(new Event("ended"));
  }
}

// let currentUtterance: SpeechSynthesisUtterance | null = null;

// export function textToSpeech(text: string, setIsChatBotSpeaking: any) {
//   if (currentUtterance) {
//     speechSynthesis.cancel();
//     currentUtterance = null;
//   }

//   const utterance = new SpeechSynthesisUtterance(text);
//   utterance.pitch = 0.8;

//   utterance.onstart = () => {
//     // Set isChatBotSpeaking to true when utterance starts
//     setIsChatBotSpeaking(true);
//   };

//   utterance.onend = () => {
//     // Set isChatBotSpeaking to false when utterance finishes
//     setIsChatBotSpeaking(false);
//   };

//   speechSynthesis.speak(utterance);
//   currentUtterance = utterance;
// }

// export function pauseTextToSpeech() {
//   console.log(currentUtterance);
//   if (currentUtterance) {
//     speechSynthesis.cancel();
//     currentUtterance = null;
//     console.log(currentUtterance);
//   }
// }
