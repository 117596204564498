import React, { useState } from "react";
import { TextField, Button, Box, Stack } from "@mui/material";
import {
  getOutline,
  DEFAULT_PROMPT_CONFIG,
  openaiApiCallStream,
} from "../util/CallOpenAI";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { isGiberish } from "../util/util";
import { GenerateOutlineInputType } from "../type/InputTypes";
import { LoadingSpinner } from "./LoadingSpinner";

export default function GenerateOutline() {
  const [loading, setLoading] = React.useState(false);
  const [questionValue, setQuestionValue] = useState<string>("");
  const [essayValue, setEssayValue] = useState("");
  const [questionValueError, setQuestionValueError] = useState<boolean>(false);
  const [questionValueErrorMessage, setQuestionValueErrorMessage] =
    useState<string>("");

  const handleGenerateButtonClick = async (event: any) => {
    event.preventDefault();

    if (
      !questionValue ||
      isGiberish(questionValue) ||
      questionValue.length < 20
    ) {
      setQuestionValueError(true);
      setQuestionValueErrorMessage(
        "Prompt is too short or can't be recognized"
      );
      return;
    }

    const essayInput: GenerateOutlineInputType = {
      essayQuestion: questionValue.trim(),
    };

    setLoading(true);
    const result = await getOutline(essayInput, DEFAULT_PROMPT_CONFIG, {
      accessToken: undefined,
    });

    try {
      // const formattedPrompt = questionValue.trim();
      // const responseStream = await openaiApiCallStream(formattedPrompt);
      // const reader = responseStream.getReader();
      // let output = "";
      // let done = false;
      // while (!done) {
      //   const { value, done: streamDone } = await reader.read();
      //   done = streamDone;
      //   if (value) output += value;
      //   setEssayValue(output);
      // }
      // setLoading(false);

      setEssayValue(result.message);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setEssayValue("Sorry, could not generate outline.");
    } finally {
      setLoading(false);
    }
  };

  const handleQuestionValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestionValueError(false);
    const inputValue = event.target.value;
    setQuestionValue(inputValue);
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="space-evenly"
      alignItems="center"
      sx={{
        mt: 10,
      }}
    >
      <Stack
        direction="row"
        spacing={3}
        sx={{ width: "600px", alignItems: "center" }}
      >
        <TextField
          value={questionValue}
          label="Essay Question"
          InputLabelProps={{
            shrink: true,
          }}
          error={questionValueError}
          helperText={questionValueError && questionValueErrorMessage}
          sx={{ width: "600px" }}
          placeholder="Type or paste an essay question"
          rows={4}
          multiline
          onChange={handleQuestionValueChange}
        />
      </Stack>
      <Stack spacing={3}>
        <Box sx={{ m: 1, position: "relative" }}>
          <Button
            variant="contained"
            onClick={handleGenerateButtonClick}
            sx={{ mb: "8px" }}
            disabled={loading}
          >
            Create Outline
          </Button>
          {loading && <LoadingSpinner />}
        </Box>
        <TextField
          value={essayValue}
          label="Outline"
          InputProps={{
            inputComponent: TextareaAutosize,
            inputProps: {
              minRows: "8",
              style: {
                resize: "none",
              },
            },
          }}
          sx={{ height: "auto", width: 600 }}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Essay outline"
          multiline
          disabled
        />
      </Stack>
    </Stack>
  );
}
