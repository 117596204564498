export const PROD_ENDPOINT = new URL(
  "https://dgguojj5wd.execute-api.us-east-2.amazonaws.com/prod"
  // "https://g8n4doyh1k.execute-api.us-east-2.amazonaws.com/dev"
);
export const TRY_ESTIMATE_URI = "/try/estimate";
export const TRY_CHAT_URI = "/try/chat";
export const TRY_OUTLINE_URI = "/try/outline";
export const TRY_SAMPLE_URI = "/try/sample";
export const TRY_USAGES_URI = "/try/usages";

export const ESTIMATE_URI = "/estimate";
export const CHAT_URI = "/chat";
export const OUTLINE_URI = "/outline";
export const SAMPLE_URI = "/sample";
export const USAGES_URI = "/usages";
