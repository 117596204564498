import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE_SPEAKING_PRACTICE } from "../routes/routes";
import { SpeakingQuestionType } from "./SpeakingQuestion";

interface TopicBoxProps {
  question: SpeakingQuestionType;
}

const TopicBox: React.FC<TopicBoxProps> = ({ question }) => {
  const navigate = useNavigate();

  function handleChooseTopic(event: any) {
    navigate(ROUTE_SPEAKING_PRACTICE, { state: question.topic });
  }

  return (
    <Grid container>
      <Grid item xs={6} style={{ padding: "10px" }}>
        <Typography variant="h6" style={{ textDecoration: "underline" }}>
          {question.topic}
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ padding: "10px" }}>
        <Typography>2023年5-8月</Typography>
      </Grid>
      <Grid item xs={6} style={{ padding: "10px" }}>
        <Typography>{question.asks[0].ask}</Typography>
      </Grid>
      <Grid item xs={6} style={{ padding: "10px" }}>
        <Button variant="contained" onClick={handleChooseTopic}>
          去练习
        </Button>
      </Grid>
    </Grid>
  );
};

export default TopicBox;
