import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
} from "@mui/material";
import TopicBox from "./TopicBox";
import data from "../data/speaking_questions.json";
import { styled } from "@mui/system";
import { SpeakingQuestionType } from "./SpeakingQuestion";

const CustomListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(0.5), // Adjust the top padding as needed
  paddingBottom: theme.spacing(0.5), // Adjust the bottom padding as needed
}));

const SpeakingSection: React.FC = () => {
  const [questions, setQuestions] = useState<SpeakingQuestionType[]>([]);
  useEffect(() => {
    const parsedData: SpeakingQuestionType[] = data.map((item: any) => ({
      topic: item.topic,
      asks: item.asks,
    }));
    setQuestions(parsedData);
  }, []);

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent="space-evenly"
      direction={"column"}
    >
      {questions.map((question, index) => (
        <Grid
          item
          key={index}
          sx={{
            // margin: 2,
            padding: 1,
            textAlign: "left",
            height: "100%",
            width: "500px",
          }}
        >
          <Paper variant="outlined">
            <TopicBox question={question} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default SpeakingSection;
