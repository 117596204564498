import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Paper,
  Tooltip,
  Divider,
  Stack,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { textToSpeech, pauseTextToSpeech } from "../util/TextToSpeech";
import ChatInputBox from "./ChatInputBox";
import useSpeechToText from "react-hook-speech-to-text";
import CampaignIcon from "@mui/icons-material/Campaign";
import SpeakingQuestion, { SpeakingQuestionType } from "./SpeakingQuestion";
import data from "../data/speaking_questions.json";
import { getQuestionByTopic, transformData } from "../util/util";
import { getFeedback } from "../util/CallOpenAI";
import { styled } from "@mui/system";

const MessageContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  minWidth: "300px", // Set a default minimum width
  [theme.breakpoints.up("sm")]: {
    width: "500px",
  },
  [theme.breakpoints.up("md")]: {
    width: "750px",
  },
  padding: theme.spacing(1.5),
  backgroundColor: "#F5F5F5",
  borderRadius: theme.spacing(1),
}));

const DividerLine = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(1)}px 3`,
  backgroundColor: theme.palette.primary.main,
}));

const DATA = transformData(data);

const SpeakingPractice = () => {
  const [previousInterimResults, setPreviousInterimResults] =
    useState<string>("");
  const [LLMResponseLoading, SetLLMResponseLoading] = useState(false);
  const [userInput, setUserInput] = useState<string>("");
  const location = useLocation();
  const { state } = location;
  const [currentQuestion, setCurrentQuestion] = useState<SpeakingQuestionType>(
    DATA[0]
  );
  const [feedbackIsLoading, setFeedbackIsLoading] = useState<boolean>(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [isChatBotSpeaking, setIsChatBotSpeaking] = useState(false);
  const [currentAskIndex, setCurrentAskIndex] = useState<number>(0);

  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    speechRecognitionProperties: {
      lang: "zh-CN",
      interimResults: true, // Allows for displaying real-time speech results
    },
    continuous: true,
    useLegacyResults: false,
  });

  const currentText = useRef("");

  useEffect(() => {
    const questionSelected = getQuestionByTopic(DATA, state);
    if (questionSelected) {
      setCurrentQuestion(questionSelected);
    }
  }, [state]);

  useEffect(() => {
    if (interimResult) {
      setUserInput(previousInterimResults + interimResult);
      currentText.current = previousInterimResults + interimResult;
    } else {
      setPreviousInterimResults(currentText.current);
    }
  }, [interimResult, previousInterimResults]);

  const handleStopSpeechToText = (): void => {
    stopSpeechToText();
    // handleChatSubmission();
  };

  function handleSpeechToText() {
    if (LLMResponseLoading) {
      // do nothing if isLoading is true
      return;
    }

    if (isRecording) {
      handleStopSpeechToText();
    } else {
      startSpeechToText();
    }
  }

  const handleUserInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedQuestion = {
        ...currentQuestion,
        asks: currentQuestion.asks.map((ask, index) => {
          if (index === currentAskIndex) {
            return {
              ...ask,
              userAnswer: event.target.value,
            };
          }
          return ask;
        }),
      };
      setCurrentQuestion(updatedQuestion);
    },
    [currentQuestion, currentAskIndex, setCurrentQuestion]
  );

  const handleChatbotSpeakingStop = (): void => {
    setIsChatBotSpeaking(false);
    pauseTextToSpeech();
  };

  const handleGetFeedback = async (): Promise<void> => {
    const currentAsk = currentQuestion.asks[currentAskIndex];
    console.log(feedbackIsLoading, feedbackText, currentAsk.userAnswer);
    if (
      feedbackIsLoading ||
      feedbackText !== "" ||
      currentAsk.userAnswer === ""
    ) {
      return;
    }
    setFeedbackIsLoading(true);

    const responseStream = await getFeedback(
      currentAsk.ask,
      currentAsk.userAnswer
    );
    setFeedbackIsLoading(false);
    const reader = responseStream.getReader();
    let output = "";
    let done = false;
    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;
      if (value) output += value;
      const updatedQuestion = {
        ...currentQuestion,
        asks: currentQuestion.asks.map((ask, index) => {
          if (index === currentAskIndex) {
            return {
              ...ask,
              feedback: output,
            };
          }
          return ask;
        }),
      };
      setCurrentQuestion(updatedQuestion);
      // setFeedbackText(output);
    }
  };

  const handleChangeQuestion = (increment: number) => {
    setCurrentAskIndex((prevIndex) => prevIndex + increment);
  };

  return (
    <Grid container direction="column" alignItems={"center"} spacing={5}>
      <Grid item xs={6} md={6}>
        <Typography>
          Part 1, Question {currentAskIndex + 1}/
          {Object.keys(currentQuestion.asks).length}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <MessageContainer>
          <Typography sx={{ textAlign: "left" }} variant="body1">
            {currentQuestion.asks[currentAskIndex].ask}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width={"100%"}
          >
            <IconButton
              // onClick={handleGetTranslation}
              size="small"
              color="primary"
              aria-label="Translate"
            >
              {/* <TranslateIcon /> 翻译 */}
              翻译
            </IconButton>
            <IconButton
              // onClick={handleSpeak}
              size="small"
              color="primary"
              aria-label="Suggest"
            >
              <CampaignIcon />
            </IconButton>
            {/* <IconButton
              // onClick={handleGetSuggestion}
              size="small"
              color="primary"
              aria-label="Speak"
              sx={{ p: 1 }}
            >
              参考答案
            </IconButton> */}
          </Stack>
          {/* {isLoading && <LoadingIndicator size={20} />}
          {displayText && <DividerLine />}
          {displayText && (
            <Typography sx={{ textAlign: "left" }} variant="body1">
              {displayText}
            </Typography>
          )} */}
        </MessageContainer>
      </Grid>
      <Grid item xs={6} md={6}>
        <MessageContainer>
          <Typography sx={{ textAlign: "left" }} variant="body1">
            参考答案
          </Typography>
          <Typography sx={{ textAlign: "left" }} variant="body1">
            {currentQuestion.asks[currentAskIndex].answer}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width={"100%"}
          >
            <IconButton
              // onClick={handleGetTranslation}
              size="small"
              color="primary"
              aria-label="Translate"
            >
              翻译
            </IconButton>
            <IconButton
              // onClick={handleSpeak}
              size="small"
              color="primary"
              aria-label="Suggest"
            >
              <CampaignIcon />
            </IconButton>
          </Stack>
        </MessageContainer>
      </Grid>
      <Grid item xs={6} md={6}>
        <MessageContainer>
          <Typography sx={{ textAlign: "left" }} variant="body1">
            你的答案
          </Typography>
          {/* <ChatInputBox
            setUserInput={setUserInput}
            userInput={userInput}
            userSpeaking={isRecording}
            chatBotSpeaking={isChatBotSpeaking}
            handleUserInput={handleUserInput}
            handleChatSubmission={handleChatSubmission}
            handleSpeechRecognition={handleSpeechToText}
            handleChatbotSpeakingStop={handleChatbotSpeakingStop}
          /> */}
          <TextField
            id="standard-basic"
            label="Your Answer"
            variant="outlined"
            value={currentQuestion.asks[currentAskIndex].userAnswer}
            onChange={handleUserInput}
            sx={{ margin: "5px", width: "100%" }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width={"100%"}
          >
            <IconButton
              onClick={handleGetFeedback}
              size="small"
              color="primary"
              aria-label="Translate"
            >
              反馈
            </IconButton>
          </Stack>
          {currentQuestion.asks[currentAskIndex].feedback !== "" && (
            <DividerLine />
          )}
          {currentQuestion.asks[currentAskIndex].feedback !== "" && (
            <Typography sx={{ textAlign: "left" }} variant="body1">
              {currentQuestion.asks[currentAskIndex].feedback}
            </Typography>
          )}
        </MessageContainer>
      </Grid>
      <Grid container item>
        <Grid md={6} item>
          <Button onClick={() => handleChangeQuestion(-1)}>上一题</Button>
        </Grid>
        <Grid md={6} item>
          <Button onClick={() => handleChangeQuestion(1)}>下一题</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SpeakingPractice;
