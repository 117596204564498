import React, { FC, ChangeEvent, KeyboardEvent } from "react";
import { Box, Grid, IconButton, Stack, TextField } from "@mui/material";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import StopIcon from "@mui/icons-material/Stop";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { translate } from "../util/CallOpenAI";

interface Props {
  setUserInput: (input: string) => void;
  userInput: string;
  userSpeaking: boolean;
  chatBotSpeaking: boolean;
  handleUserInput: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChatSubmission: () => void;
  handleSpeechRecognition: () => void;
  handleChatbotSpeakingStop: () => void;
  handleClearChatHistory?: () => void;
}

const ChatInputBox: FC<Props> = ({
  setUserInput,
  userInput,
  userSpeaking,
  chatBotSpeaking,
  handleUserInput,
  handleChatSubmission,
  handleSpeechRecognition,
  handleChatbotSpeakingStop,
  handleClearChatHistory,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleChatSubmission();
    }
  };

  const handleTranslate = async () => {
    const responseStream = await translate(userInput, "English");
    const reader = responseStream.getReader();
    let output = "";
    let done = false;
    while (!done) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;
      if (value) output += value;
      setUserInput(output);
    }
  };

  return (
    <Box
      sx={{
        width: "750px",
        display: "flex",
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 1)",
        overflow: "hidden",
        borderRadius: "10px",
        mb: 6,
        position: "fixed",
        border: "1px solid #d3d3d3",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        <Grid item xs={6} md={12}>
          <Stack direction="row" sx={{}}>
            {handleClearChatHistory && (
              <IconButton onClick={handleClearChatHistory}>
                <CleaningServicesIcon />
              </IconButton>
            )}

            <Stack
              direction="row"
              sx={{
                width: "100%",
              }}
            >
              <TextField
                value={userInput}
                variant="standard"
                sx={{
                  width: "90%",
                  ml: "1rem",
                  mt: "0.25rem",
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                    backgroundColor: "white",
                  },
                }}
                placeholder="Type or speak, in English or Chinese!"
                onChange={handleUserInput}
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { border: "none", backgroundColor: "white" },
                }}
                maxRows={8}
                multiline
                onKeyDown={handleKeyDown}
              />
              <IconButton
                onClick={
                  chatBotSpeaking
                    ? handleChatbotSpeakingStop
                    : handleChatSubmission
                }
              >
                <SendIcon />
              </IconButton>
              <IconButton onClick={handleTranslate}>
                <TranslateIcon />
              </IconButton>
              {!isMobile && (
                <IconButton
                  onClick={
                    chatBotSpeaking
                      ? handleChatbotSpeakingStop
                      : handleSpeechRecognition
                  }
                >
                  {chatBotSpeaking ? (
                    <StopIcon />
                  ) : (
                    <MicIcon
                      style={{ color: userSpeaking ? "#8BC34A" : "inherit" }}
                    />
                  )}
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatInputBox;
