import { insertSystemMessage } from "./util";
import {
  GenerateOutlineInputType,
  GenerateSampleInput,
  OutlineToEssayInput,
  FeedbackInput,
} from "../type/InputTypes";
import { SpeakingPart1QuestionType } from "../SpeakingPart1Questions";

import {
  PROD_ENDPOINT,
  TRY_ESTIMATE_URI,
  ESTIMATE_URI,
  CHAT_URI,
  TRY_CHAT_URI,
  OUTLINE_URI,
  TRY_OUTLINE_URI,
  SAMPLE_URI,
  TRY_SAMPLE_URI,
} from "../constants/api";
import { Actions, withLogging } from "../analytics/analytics";
import { Usage } from "../type/usage";
const API_KEY = "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";

type PromptResponse = {
  message: string;
  usage: Usage;
};

type PromptConfig = {
  maxRetries: number;
  retryIntervalMS: number;
};

type AuthConfig = {
  accessToken?: string;
};

export const DEFAULT_PROMPT_CONFIG: PromptConfig = {
  maxRetries: 3,
  retryIntervalMS: 1000,
};

/**
 * Returns the URL for the given prompt action.
 *
 * @param config - The authentication configuration.
 * @param action - The prompt action.
 * @returns The URL for the given prompt action.
 * @throws An error if the action is not valid or if there's an error during execution.
 */
const getPromptURL = (
  config: AuthConfig,
  action: "chat" | "estimate" | "sample" | "outline"
): string => {
  if (!config || !action) {
    throw new Error("Invalid arguments.");
  }

  let uri: string;
  switch (action) {
    case "chat":
      uri = config.accessToken ? CHAT_URI : TRY_CHAT_URI;
      break;
    case "estimate":
      uri = config.accessToken ? ESTIMATE_URI : TRY_ESTIMATE_URI;
      break;
    case "outline":
      uri = config.accessToken ? OUTLINE_URI : TRY_OUTLINE_URI;
      break;
    case "sample":
      uri = config.accessToken ? SAMPLE_URI : TRY_SAMPLE_URI;
      break;
    default:
      throw new Error(`Invalid action: ${action}`);
  }

  return PROD_ENDPOINT + uri;
};

const getPromptHeaders = (config: AuthConfig): Headers => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  config.accessToken &&
    headers.append("Authorization", `Bearer ${config.accessToken}`);
  return headers;
};

const validatePromptResponse = (jsonResp: any): PromptResponse | never => {
  if (!jsonResp["message"]) {
    throw new Error("response missing message");
  }
  if (!jsonResp["usage"]) {
    throw new Error("response missing usage");
  }
  return jsonResp as PromptResponse;
};

async function _getWritingBandEstimate(
  prompt: string,
  essay: string,
  promptConfig: PromptConfig,
  authConfig: {
    accessToken?: string;
  }
): Promise<PromptResponse> {
  let retries = 0;

  const url = getPromptURL(authConfig, "estimate");
  const requestInit: RequestInit = {
    method: "POST",
    headers: getPromptHeaders(authConfig),
    body: JSON.stringify({
      prompt: prompt,
      essay: essay,
    }),
  };
  while (retries < promptConfig.maxRetries) {
    try {
      const response = await fetch(url.toString(), requestInit);
      return validatePromptResponse(await response.json());
    } catch (error) {
      console.error(
        `Request failed.Retrying in ${promptConfig.retryIntervalMS}ms...`
      );
      retries++;
      await new Promise((resolve) =>
        setTimeout(resolve, promptConfig.retryIntervalMS)
      );
    }
  }
  console.error(
    `Request failed after ${promptConfig.maxRetries} attempts.Giving up...`
  );
  throw new Error(
    `Request failed after ${promptConfig.maxRetries} attempts.Giving up...`
  );
}

async function _getSampleEssay(
  essayInput: GenerateSampleInput,
  promptConfig: PromptConfig,
  authConfig: {
    accessToken?: string;
  }
): Promise<PromptResponse> {
  let retries = 0;

  const url = getPromptURL(authConfig, "sample");
  const requestInit: RequestInit = {
    method: "POST",
    headers: getPromptHeaders(authConfig),
    body: JSON.stringify({
      essayQuestion: essayInput.essayQuestion,
      band: essayInput.band,
    }),
  };
  while (retries < promptConfig.maxRetries) {
    try {
      const response = await fetch(url.toString(), requestInit);
      return validatePromptResponse(await response.json());
    } catch (error) {
      console.error(
        `Request failed.Retrying in ${promptConfig.retryIntervalMS}ms...`
      );
      retries++;
      await new Promise((resolve) =>
        setTimeout(resolve, promptConfig.retryIntervalMS)
      );
    }
  }
  console.error(
    `Request failed after ${promptConfig.maxRetries} attempts.Giving up...`
  );
  throw new Error(
    `Request failed after ${promptConfig.maxRetries} attempts.Giving up...`
  );
}

async function _getOutline(
  essayInput: GenerateOutlineInputType,
  promptConfig: PromptConfig,
  authConfig: {
    accessToken?: string;
  }
): Promise<PromptResponse> {
  let retries = 0;

  const url = getPromptURL(authConfig, "outline");
  const requestInit: RequestInit = {
    method: "POST",
    headers: getPromptHeaders(authConfig),
    body: JSON.stringify({
      essayQuestion: essayInput.essayQuestion,
    }),
  };
  while (retries < promptConfig.maxRetries) {
    try {
      const response = await fetch(url.toString(), requestInit);
      return validatePromptResponse(await response.json());
    } catch (error) {
      console.error(
        `Request failed.Retrying in ${promptConfig.retryIntervalMS}ms...`
      );
      retries++;
      await new Promise((resolve) =>
        setTimeout(resolve, promptConfig.retryIntervalMS)
      );
    }
  }
  console.error(
    `Request failed after ${promptConfig.maxRetries} attempts.Giving up...`
  );
  throw new Error(
    `Request failed after ${promptConfig.maxRetries} attempts.Giving up...`
  );
}

const systemMessage = `
  You are a friendly IELTS coach that always respond to user in English. Do not mention you are an AI
  Help user practice IELTS speaking exam.
  Ask questions one at a time.
  Be Concise.
  `;

// const systemMessage = `
//   You are a friendly assistant. Don't mention you are an AI.
//   Your users may want to speak to you to practice English.
//   If they ask to have a conversation with you, ask one question. one at a time.
//   Be Concise in your response. Always respond to user in English.
//   `;

function formatSystemMessage(
  systemMessage: string,
  systemMessageAddition: SpeakingPart1QuestionType
) {
  return `${systemMessage}
  The current question is ${
    systemMessageAddition.QuestionName
  }. Here's the list of questions to ask:
  ${systemMessageAddition.Questions.join("\n")}
  `;
}
async function _getChat(
  userPrompt: string,
  promptConfig: PromptConfig,
  authConfig: {
    accessToken?: string;
  },
  systemMessageAddition: SpeakingPart1QuestionType,
  history?: Array<{ role: string; content: string }>
): Promise<PromptResponse> {
  let retries = 0;
  console.log(
    insertSystemMessage(
      formatSystemMessage(systemMessage, systemMessageAddition),
      history
    )
  );
  const url = getPromptURL(authConfig, "chat");
  const requestInit: RequestInit = {
    method: "POST",
    headers: getPromptHeaders(authConfig),
    body: JSON.stringify({
      userPrompt: userPrompt,
      history: insertSystemMessage(
        formatSystemMessage(systemMessage, systemMessageAddition),
        history
      ),
    }),
  };
  while (retries < promptConfig.maxRetries) {
    try {
      const response = await fetch(url.toString(), requestInit);
      return validatePromptResponse(await response.json());
    } catch (error) {
      console.error(
        `Request failed.Retrying in ${promptConfig.retryIntervalMS}ms...`
      );
      retries++;
      await new Promise((resolve) =>
        setTimeout(resolve, promptConfig.retryIntervalMS)
      );
    }
  }
  console.error(
    `Request failed after ${promptConfig.maxRetries} attempts.Giving up...`
  );
  throw new Error(
    `Request failed after ${promptConfig.maxRetries} attempts.Giving up...`
  );
}

async function translate(text: string, language?: string) {
  const prompt = `Translate the text in <> to ${
    language ? language : "Chinese"
  }. <${text}>`;
  return openaiApiCallStream(prompt);
}

async function getFeedback(ask: string, userAnswer: string) {
  const prompt = `
  给学生反馈来改良他们的的答案让他们可以拿到更好的分数。至少举两个具体的例子.
  问题: ${ask}
  学生答案: ${userAnswer}
  `;
  return openaiApiCallStream(prompt, [
    { role: "system", content: "你是一个帮助学生进步英语的雅思老师" },
  ]);
}

async function checkGrammar(text: string) {
  const prompt = `Check the grammar of the text in <>: <${text}>`;
  return openaiApiCallStream(prompt);
}

async function suggest(text: string) {
  const prompt = `Show three high IELTS speaking test band answer to respond to this question in <> <${text}>`;
  return openaiApiCallStream(prompt);
}

async function openaiApiCallStream(
  formattedPrompt: string,
  history?: Array<{ role: string; content: string }>
) {
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${API_KEY}`,
    },
    body: JSON.stringify({
      messages: history
        ? history.concat([{ role: "user", content: formattedPrompt }])
        : [{ role: "user", content: formattedPrompt }],
      max_tokens: 256,
      temperature: 0.7,
      frequency_penalty: 0,
      model: "gpt-3.5-turbo",
      stream: true,
    }),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to fetch response, status code: ${response.status}`
    );
  }

  if (!response.body) {
    throw new Error(`Response body is null or undefined`);
  }

  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
  interface Delta {
    content?: string;
    role?: string;
  }

  interface Choice {
    delta: Delta;
    index: number;
    finish_reason: null;
  }

  interface ChatCompletion {
    id: string;
    object: string;
    created: number;
    model: string;
    choices: Choice[];
  }
  return new ReadableStream({
    async pull(controller) {
      const { value, done } = await reader.read();
      // console.log(value?.split("\n\n"));
      try {
        if (done) {
          controller.close();
          return;
        }
        const results = value?.split("\n\n");
        if (results) {
          for (const result of results) {
            const val = result.replace(/data: /g, "");
            if (val === "" || val === "[DONE]") return;
            const parsedValue: ChatCompletion = JSON.parse(val.trim());
            const text = parsedValue.choices[0].delta?.content || "";
            controller.enqueue(text);
          }
        }
      } catch (error) {
        controller.error(error);
      }
    },
    cancel() {
      reader.cancel();
    },
  });
}

const getWritingBandEstimate = withLogging(
  _getWritingBandEstimate,
  Actions.Estimate
);
const getChat = withLogging(_getChat, Actions.Chat);
const getOutline = withLogging(_getOutline, Actions.Outline);
const getSampleEssay = withLogging(_getSampleEssay, Actions.Sample);
export {
  openaiApiCallStream,
  getWritingBandEstimate,
  getChat,
  getOutline,
  getFeedback,
  getSampleEssay,
  translate,
  checkGrammar,
  suggest,
};
