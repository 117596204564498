import React, { FC } from "react";
import { Button, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ROUTE_HOME,
  ROUTE_ESTIMATE,
  ROUTE_CHAT,
  ROUTE_SAMPLE,
  ROUTE_OUTLINE,
} from "../routes/routes";

const Navigation: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const chatSelected =
    location.pathname === ROUTE_CHAT || location.pathname === ROUTE_HOME;
  const estimateSelected = location.pathname === ROUTE_ESTIMATE;
  const outlineSelected = location.pathname === ROUTE_OUTLINE;
  const sampleSelected = location.pathname === ROUTE_SAMPLE;

  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant={chatSelected ? "contained" : "text"}
        onClick={() => navigate(ROUTE_CHAT)}
      >
        Voice Chat
      </Button>
      <Button
        variant={sampleSelected ? "contained" : "text"}
        onClick={() => navigate(ROUTE_SAMPLE)}
      >
        Generate Sample Essay
      </Button>
      <Button
        variant={outlineSelected ? "contained" : "text"}
        onClick={() => navigate(ROUTE_OUTLINE)}
      >
        Get Outline
      </Button>
      <Button
        variant={estimateSelected ? "contained" : "text"}
        onClick={() => navigate(ROUTE_ESTIMATE)}
      >
        Estimate Band
      </Button>
    </Stack>
  );
};

export default Navigation;
