import React, { FC } from "react";
import { Button, Stack, Drawer, Box, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  ROUTE_ESTIMATE,
  ROUTE_CHAT,
  ROUTE_SAMPLE,
  ROUTE_OUTLINE,
} from "../routes/routes";

const list = (handleListItemClick: (route: string) => void) => (
  <Box role="presentation">
    <List>
      {["Voice Chat", "Generate Essay", "Outline", "Estimate Band"].map(
        (text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => handleListItemClick(text)}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        )
      )}
    </List>
  </Box>
);
interface Props {
  open: boolean;
  toggleDrawer: () => void;
}

const MenuDrawer: FC<Props> = ({ open, toggleDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleListItemClick = (route: string): void => {
    if (route === "Voice Chat") {
      navigate(ROUTE_CHAT);
    } else if (route === "Generate Essay") {
      navigate(ROUTE_SAMPLE);
    } else if (route === "Outline") {
      navigate(ROUTE_OUTLINE);
    } else if (route === "Estimate Band") {
      navigate(ROUTE_ESTIMATE);
    }
  };

  return (
    <Drawer anchor="left" open={open} onClose={toggleDrawer}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={toggleDrawer}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      {list(handleListItemClick)}
    </Drawer>
  );
};

export default MenuDrawer;
