import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ErrorBoundary from "./components/ErrorBoundary";
import TopBar from "./components/TopBar";
import VoiceChat from "./components/VoiceChat";
import EstimateBand from "./components/EstimateBand";
import SpeakingPractice from "./components/SpeakingPractice";
import SpeakingSection from "./components/SpeakingSection";
import { I18nextProvider } from "react-i18next";
import Main from "./components/Main";

import {
  ROUTE_CHAT,
  ROUTE_ESTIMATE,
  ROUTE_HOME,
  ROUTE_OUTLINE,
  ROUTE_SAMPLE,
  ROUTE_SPEAKING_PRACTICE,
} from "./routes/routes";
import GenerateOutline from "./components/GenerateOutline";
import Generate from "./components/Generate";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2e1667",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <Router>
            <TopBar />
            <div style={{ marginTop: "80px" }}>
              <Routes>
                {/* TODO: Add 404 page for non-existing route */}
                <Route
                  path={ROUTE_HOME}
                  element={<Navigate to={ROUTE_CHAT} replace />}
                />
                <Route path={ROUTE_CHAT} element={<VoiceChat />} />
                <Route path={ROUTE_OUTLINE} element={<GenerateOutline />} />
                <Route path={ROUTE_SAMPLE} element={<Generate />} />
                <Route path={ROUTE_ESTIMATE} element={<EstimateBand />} />
                <Route
                  path={ROUTE_SPEAKING_PRACTICE}
                  element={<SpeakingPractice />}
                />
                <Route path="/test" element={<SpeakingSection />} />
              </Routes>
            </div>
          </Router>
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
