import { CircularProgress, CircularProgressProps } from "@mui/material";

interface LoadingSpinnerProps extends CircularProgressProps { }

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  return (
    <CircularProgress
      size={24}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: "-12px",
        marginLeft: "-12px",
      }}
      {...props}
    />
  );
};
