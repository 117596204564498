import React, { useState } from "react";
import { TextField, Button, Box, Stack } from "@mui/material";
import { getSampleEssay, DEFAULT_PROMPT_CONFIG } from "../util/CallOpenAI";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { GenerateSampleInput } from "../type/InputTypes";
import { isGiberish } from "../util/util";
import { LoadingSpinner } from "./LoadingSpinner";

export default function Generate() {
  const [loading, setLoading] = React.useState(false);
  const [questionValue, setQuestionValue] = useState<string>("");
  const [bandValue, setBandValue] = useState("");
  const [essayValue, setEssayValue] = useState("");
  const [bandValueError, setBandValueError] = useState<boolean>(false);
  const [questionValueError, setQuestionValueError] = useState<boolean>(false);
  const [questionValueErrorMessage, setQuestionValueErrorMessage] =
    useState<string>("");

  const handleGenerateButtonClick = async (event: any) => {
    event.preventDefault();
    setEssayValue("");

    const isNumber = /^\d$/.test(bandValue) && bandValue !== "0";
    if (
      !questionValue ||
      isGiberish(questionValue) ||
      questionValue.length < 20
    ) {
      setQuestionValueError(true);
      setQuestionValueErrorMessage(
        "Prompt is too short or can't be recognized"
      );
      return;
    } else if (!isNumber) {
      setBandValueError(true);
      return;
    }

    const essayInput: GenerateSampleInput = {
      essayQuestion: questionValue.trim(),
      band: parseFloat(bandValue.trim()),
    };

    setLoading(true);
    const result = await getSampleEssay(essayInput, DEFAULT_PROMPT_CONFIG, {
      accessToken: undefined,
    });
    setEssayValue(result.message);
    setLoading(false);
  };

  const handleBandValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBandValueError(false);
    setBandValue(event.target.value.trim());
  };

  const handleQuestionValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestionValueError(false);
    setQuestionValue(event.target.value);
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="space-evenly"
      alignItems="center"
      sx={{
        mt: 10,
      }}
    >
      <Stack
        direction="row"
        spacing={3}
        sx={{ width: "600px", alignItems: "center" }}
      >
        <TextField
          value={questionValue}
          label="Essay Question"
          InputLabelProps={{
            shrink: true,
          }}
          error={questionValueError}
          helperText={questionValueError && questionValueErrorMessage}
          sx={{ width: "600px" }}
          placeholder="Type or paste an essay question"
          rows={4}
          multiline
          onChange={handleQuestionValueChange}
        />
        <TextField
          label="Band"
          variant="standard"
          value={bandValue}
          onChange={handleBandValueChange}
          error={bandValueError}
          helperText={bandValueError && "Band value has to be between 1-9"}
        />
      </Stack>
      <Stack spacing={3}>
        <Box sx={{ m: 1, position: "relative" }}>
          <Button
            variant="contained"
            onClick={handleGenerateButtonClick}
            sx={{ mb: "8px" }}
            disabled={loading}
          >
            Get Sample Essay
          </Button>
          {loading && <LoadingSpinner />}
        </Box>
        <TextField
          value={essayValue}
          label="Essay"
          InputProps={{
            inputComponent: TextareaAutosize,
            inputProps: {
              minRows: "8",
              style: {
                resize: "none",
              },
            },
          }}
          sx={{ height: "auto", width: 600 }}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Generated essay will appear here"
          multiline
          disabled
        />
      </Stack>
    </Stack>
  );
}
